<template>
  <main class="content-page">
    <section class="submenu">
      <span class="highlight"></span>
      <h1 class="-h6">Elements</h1>

      <section class="actions">
        <div class="btn-container">
          <Button
            :state="'primary'"
            :title="'Add Element'"
            @click="setModal('create')"
          />
        </div>
        <div class="search-container">
          <SearchBar />
        </div>
      </section>
    </section>

    <div class="listing"><ElementsListing @setModal="setModal" /></div>
  </main>
</template>

<script>
import SearchBar from "../../../components/utils/BaseSearchBar.vue";
import Button from "../../../components/utils/BaseButton.vue";
import ElementsListing from "../../../components/tables/mscope/ElementsListing.vue";
export default {
  components: { SearchBar, Button, ElementsListing },
  props: ["getRouteName"],
  methods: {
    setModal(cmd, item) {
      const route = this.getRouteName();
      const scopeModal = {
        cmd: cmd,
        scope: route,
        item: item,
      };
      this.$store.dispatch("scopeModal", scopeModal);
    },
  },
};
</script>

<style scoped>
@import url("../../../assets/scopeListings.css");
</style>